import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=325a2c5a"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=325a2c5a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default,Theme2ComponentsExchangeRate: require('/app/components/Theme2/Components/ExchangeRate.vue').default,Theme2DesktopNavProfileDropdown: require('/app/components/Theme2/Desktop/Nav/ProfileDropdown.vue').default,CommonsLanguageSwitcher: require('/app/components/Commons/LanguageSwitcher.vue').default,Theme2DesktopNavMessageDropdown: require('/app/components/Theme2/Desktop/Nav/MessageDropdown.vue').default,Theme2DesktopMenuBar: require('/app/components/Theme2/Desktop/MenuBar.vue').default})
